import { Helmet } from "react-helmet";
import "./App.css";
import HomeFooter from "./components/HomePage/HomeFooter";
import HomeHeader from "./components/HomePage/HomeHeader";
import OurStory from "./components/OurStory/OurStory";
import { appRoutes } from "./constants/Routes";

export default function OurStoryPage() {
  return (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bizbulk's story</title>
        <meta name="description" content="Bizbulk's story" />
        <link rel="canonical" href={appRoutes.ourStory} />
      </Helmet>
      <HomeHeader />
      <OurStory />
      <HomeFooter />
    </div>
  );
}
