import "./ProductPreviewSheet.css";
import { useNavigate } from "react-router-dom";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import CategoryIcon from "@mui/icons-material/Category";
import ScaleIcon from "@mui/icons-material/Scale";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { appRoutes } from "../../../constants/Routes";

export default function ProductPreviewSheet({ product }) {
  const navigate = useNavigate();
  const bbColor = "#06AA90";

  const convertToDollarString = (valueInCents) => {
    const double = +valueInCents
    const valueInDollars = double/100
    return `$${valueInDollars.toFixed(2)}`
  }
  return (
    <div className="product-preview-sheet">
      <div className="back-button" onClick={() => navigate(appRoutes.webapp)}>
        <ArrowBackIcon />
        Back
      </div>
      <p className="product-name">{product.name}</p>
      <div className="info-row">
        <div className="info-row-item">
          <AttachMoneyIcon style={{ color: `${bbColor}` }} />
          <p>
            <b>Original price</b>&nbsp;{convertToDollarString(product.storePrice)}
          </p>
        </div>
        <div className="info-row-item">
          <MoneyOffIcon style={{ color: `${bbColor}` }} />
          <p>
            <b>Price</b>&nbsp;{convertToDollarString(product.groupBuyPrice)}
          </p>
        </div>
      </div>
      <div className="info-row">
        <div className="info-row-item">
          <CategoryIcon style={{ color: `${bbColor}` }} />
          <p>
            <b>Category</b>&nbsp;{product.category}
          </p>
        </div>
        <div className="info-row-item">
          <ScaleIcon style={{ color: `${bbColor}` }} />
          <p>
            <b>Unit</b>&nbsp;{product.unit}
          </p>
        </div>
      </div>
      <p className="para-text">
        <b>Description</b>&nbsp;{product.description}
      </p>
      <p className="para-text">
        <b>Important info</b>&nbsp;{product.importantInfo}
      </p>
    </div>
  );
}
