import React, { useEffect } from "react";
import "./Faq.css";

export default function FaqContent() {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
    <div className="faq">
      <h1>FREQUENTLY ASKED QUESTIONS</h1>
      <div className="faq-content">
        <p className="faq-section-head">GENERAL Q&A</p>
        <p className="faq-section-question">
          Q1: Why is Bizbulk different from other group buy platforms?
        </p>
        <p className="faq-section-answer">
          A1: Bizbulk takes pride in quality and reputation. As such, Bizbulk
          only partners with suppliers who have a proven track record or a
          reputable name in the B2B industry. Bizbulk goes through a stringent
          process of curating its suppliers so you don’t have to. Hence no
          matter what product you purchase, you can be assured of quality.
        </p>
        <p className="faq-section-question">
          Q2: What products can be found on the Bizbulk platform
        </p>
        <p className="faq-section-answer">
          A2: Any product that is considered premium/luxury. It generally
          includes products that are not considered a daily necessity
        </p>
        <p className="faq-section-head">APP RELATED Q&A</p>
        <p className="faq-section-question">Q1: When is payment made?</p>
        <p className="faq-section-answer">
          A1: Only when a group has met the MOQ (regardless of whether the
          deadline has been reached), will there be a prompt in app to make
          payment
        </p>
        <p className="faq-section-question">
          Q2: What happens if you did not make payment by the deadline?
        </p>
        <p className="faq-section-answer">
          Q2: You will be removed from the group.
        </p>
        <p className="faq-section-question">
          Q3: What happens if MOQ has been met, but not all have paid by the
          deadline?
        </p>
        <p className="faq-section-answer">
          A3: As long as the total paid amount is within a % threshold set by
          the supplier, they will be prompted to accept/reject the gro up buy.
          If it is rejected, refunds will be made to those who have paid.
        </p>
        <p className="faq-section-question">
          Q4: Who/when do I collect the products from?
        </p>
        <p className="faq-section-answer">
          A4: For a group to exist, it needs to have a host (either you or
          someone else). The host decides what the delivery location should be
          and every other participant of that group will go the host’s address
          to collect their purchase on the day of delivery by 10pm.
        </p>
      </div>
    </div>
  );
}
