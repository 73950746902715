export class ProductDetails {
  static NAME = "name";
  static BRAND = "brand";
  static COUNTRY_OF_ORIGIN = "countryOfOrigin";
  static DESCRIPTION = "description";
  static CATEGORY = "category";
  static SUBCATEGORY = "subCategory";
  static STORE_PRICE = "storePrice";
  static BIZBULK_PRICE = "groupBuyPrice";
  static IMPORTANT_INFO = "importantInfo";
  static IMAGE_URL = "imageUrl";
  static UNIT = "unit";
  static SELLER_ID = "sellerId";
  static CREATED_ON = "createdOn";
  static UPDATED_ON = "updatedOn";
  static SEARCH_TAGS = "searchTags";

  constructor({
    name,
    brand,
    countryOfOrigin,
    description,
    category,
    subCategory,
    storePrice,
    groupBuyPrice,
    unit,
    sellerId,
    createdOn,
    updatedOn,
    searchTags,
    importantInfo = null,
    imageURL = null,
  }) {
    this.name = name;
    this.brand = brand;
    this.countryOfOrigin = countryOfOrigin;
    this.description = description;
    this.category = category;
    this.subCategory = subCategory;
    this.storePrice = storePrice;
    this.groupBuyPrice = groupBuyPrice;
    this.unit = unit;
    this.sellerId = sellerId;
    this.createdOn = createdOn;
    this.updatedOn = updatedOn;
    this.searchTags = searchTags;
    this.importantInfo = importantInfo;
    this.imageURL = imageURL;
  }

  static empty() {
    return new ProductDetails({
      name: "",
      brand: "",
      countryOfOrigin: "",
      description: "",
      category: "",
      subCategory: "",
      storePrice: 0,
      groupBuyPrice: 0,
      unit: "unit",
      sellerId: "",
      searchTags: [],
      createdOn: new Date(),
      updatedOn: new Date(),
    });
  }

  toJson() {
    return {
      [ProductDetails.NAME]: this.name,
      [ProductDetails.BRAND]: this.brand,
      [ProductDetails.COUNTRY_OF_ORIGIN]: this.countryOfOrigin,
      [ProductDetails.DESCRIPTION]: this.description,
      [ProductDetails.CATEGORY]: this.category,
      [ProductDetails.SUBCATEGORY]: this.subCategory,
      [ProductDetails.STORE_PRICE]: this.storePrice.toString(),
      [ProductDetails.BIZBULK_PRICE]: this.groupBuyPrice.toString(),
      [ProductDetails.IMPORTANT_INFO]: this.importantInfo,
      [ProductDetails.IMAGE_URL]: this.imageURL,
      [ProductDetails.UNIT]: this.unit,
      [ProductDetails.SELLER_ID]: this.sellerId,
      [ProductDetails.CREATED_ON]: this.createdOn.toISOString(),
      [ProductDetails.UPDATED_ON]: this.updatedOn.toISOString(),
    };
  }

  static fromJson(json) {
    const searchTags = json[ProductDetails.SEARCH_TAGS] || [];
    return new ProductDetails({
      name: json[ProductDetails.NAME] || "",
      brand: json[ProductDetails.BRAND] || "",
      countryOfOrigin: json[ProductDetails.COUNTRY_OF_ORIGIN] || "",
      description: json[ProductDetails.DESCRIPTION] || "",
      category: json[ProductDetails.CATEGORY] || "",
      subCategory: json[ProductDetails.SUBCATEGORY] || "",
      storePrice: parseInt(json[ProductDetails.STORE_PRICE].toString()),
      groupBuyPrice: parseInt(json[ProductDetails.BIZBULK_PRICE].toString()),
      sellerId: json[ProductDetails.SELLER_ID] || "",
      unit: json[ProductDetails.UNIT] || "",
      importantInfo: json[ProductDetails.IMPORTANT_INFO],
      imageURL: json[ProductDetails.IMAGE_URL],
      createdOn: json[ProductDetails.CREATED_ON],
      updatedOn: json[ProductDetails.UPDATED_ON],
      searchTags: searchTags,
    });
  }
}

export class OfferDetails {
  static PRODUCT_IDS = "productIds";
  static SELLER_ID = "sellerId";
  static TITLE = "title";
  static PREP_DAYS = "prepDays";
  static MIN_AMOUNT = "minAmount";
  static MAX_AMOUNT = "maxAmount";
  static IS_MINIMUM_VALUE = "isMinimumValue";
  static DESCRIPTION = "description";
  static OFFER_STATUS = "offerStatus";
  static CREATED_ON = "createdOn";
  static UPDATED_ON = "updatedOn";
  static SEARCH_TAGS = "searchTags";
  static MAX_GROUPS = "maxGroups";
  static LIVE_GROUPS = "liveGroups";

  constructor({
    productIds,
    sellerId,
    title,
    prepDays,
    minAmount,
    maxAmount,
    description,
    isMinimumValue,
    createdOn,
    updatedOn,
    searchTags,
    offerStatus,
    maxGroups,
    liveGroups,
  }) {
    this.productIds = productIds;
    this.sellerId = sellerId;
    this.title = title;
    this.prepDays = prepDays;
    this.minAmount = minAmount;
    this.maxAmount = maxAmount;
    this.description = description;
    this.isMinimumValue = isMinimumValue;
    this.createdOn = createdOn;
    this.updatedOn = updatedOn;
    this.searchTags = searchTags;
    this.offerStatus = offerStatus;
    this.maxGroups = maxGroups;
    this.liveGroups = liveGroups;
  }

  static empty() {
    return new OfferDetails({
      productIds: [],
      sellerId: "",
      title: "",
      prepDays: 0,
      minAmount: 0,
      maxAmount: null,
      description: "",
      isMinimumValue: true,
      createdOn: new Date(),
      updatedOn: new Date(),
      searchTags: [],
      offerStatus: "Unknown",
      maxGroups: null,
      liveGroups: [],
    });
  }

  static fromJson(json) {
    const searchTags =
      document[OfferDetails.SEARCH_TAGS] != null
        ? [...document[OfferDetails.SEARCH_TAGS]]
        : [];
    const liveGroups =
      json.hasOwnProperty(OfferDetails.LIVE_GROUPS) &&
      document[OfferDetails.LIVE_GROUPS] != null
        ? [...document[OfferDetails.LIVE_GROUPS]]
        : [];
    const status = json[OfferDetails.OFFER_STATUS] || "Unknown";
    return new OfferDetails({
      productIds: [...json[OfferDetails.PRODUCT_IDS]],
      sellerId: json[OfferDetails.SELLER_ID],
      title: json[OfferDetails.TITLE],
      prepDays: json[OfferDetails.PREP_DAYS],
      minAmount: Math.floor(json[OfferDetails.MIN_AMOUNT]),
      maxAmount:
        json[OfferDetails.MAX_AMOUNT] != null
          ? Math.floor(json[OfferDetails.MAX_AMOUNT])
          : null,
      description: json[OfferDetails.DESCRIPTION],
      isMinimumValue: json[OfferDetails.IS_MINIMUM_VALUE],
      createdOn: OfferDetails.CREATED_ON,
      updatedOn: OfferDetails.UPDATED_ON,
      searchTags: searchTags,
      offerStatus: status.offerStatus,
      liveGroups: liveGroups,
      maxGroups: json[OfferDetails.MAX_GROUPS],
    });
  }
}

export class GroupDetails {
  static OFFER_ID = "offerId";
  static MIN_AMOUNT = "minAmount";
  static IS_MINIMUM_VALUE = "isMinimumValue";
  static MAX_AMOUNT = "maxAmount";
  static PREP_DAYS = "prepDays";
  static SELLER_ID = "sellerId";
  static PRODUCTS = "products";
  static HOST_ID = "hostId";
  static GROUP_EXPIRY_DATE = "groupExpiryDate";
  static DELIVERY_DATE = "deliveryDate";
  static PICKUP_LOCATION = "pickupLocation";
  static TITLE = "title";
  static CHAT_MESSAGES = "chatMessages";
  static MEMBER_ORDERS = "memberOrders";
  static CREATED_ON = "createdOn";
  static UPDATED_ON = "updatedOn";
  static STATUS = "status";
  static INVITATION_CODE = "invitationCode";
  static SEARCH_TAGS = "searchTags";
  static REFUNDED = "refunded";
  static DIST_FROM_LOC = "distFromLoc";
  static PROPOSED_DELIVERY_DATE = "proposedDeliveryDate";
  static DELIVERY_DATE_VOTES = "deliveryDateVotes";

  constructor({
    offerId,
    minAmount,
    maxAmount,
    isMinimumValue,
    prepDays,
    sellerId,
    products,
    hostId,
    groupExpiry,
    pickupLocation,
    title,
    memberOrders,
    chatMessages,
    createdOn,
    updatedOn,
    status,
    searchTags,
    invitationCode,
    deliveryDate,
    refunded,
    distFromLoc,
    proposedDeliveryDate,
    deliveryDateVotes,
  }) {
    this.offerId = offerId;
    this.minAmount = minAmount;
    this.maxAmount = maxAmount;
    this.isMinimumValue = isMinimumValue;
    this.prepDays = prepDays;
    this.sellerId = sellerId;
    this.products = products;
    this.hostId = hostId;
    this.groupExpiry = groupExpiry;
    this.pickupLocation = pickupLocation;
    this.title = title;
    this.memberOrders = memberOrders;
    this.chatMessages = chatMessages;
    this.createdOn = createdOn;
    this.updatedOn = updatedOn;
    this.status = status;
    this.searchTags = searchTags;
    this.invitationCode = invitationCode;
    this.deliveryDate = deliveryDate;
    this.refunded = refunded;
    this.distFromLoc = distFromLoc;
    this.proposedDeliveryDate = proposedDeliveryDate;
    this.deliveryDateVotes = deliveryDateVotes;
  }

  static fromJson(json) {

    const memberOrders = Object.fromEntries(
      Object.entries(json[GroupDetails.MEMBER_ORDERS]).map(
        ([buyerId, singleOrder]) => [buyerId, JSON.stringify(singleOrder)]
      )
    );

    const status = json[GroupDetails.STATUS] || "Unknown";

    const messagesJson = json[GroupDetails.CHAT_MESSAGES] || [];
    const messages = messagesJson.map((json) => JSON.stringify(json));

    const products = json[GroupDetails.PRODUCTS] || {};
    const productsMap = Object.fromEntries(
      Object.entries(products).map(([buyerId, productDetail]) => [
        buyerId,
        ProductDetails.fromJson(productDetail),
      ])
    );

    const location =
      json[GroupDetails.PICKUP_LOCATION] != null
        ? MapLocation.fromJson(json[GroupDetails.PICKUP_LOCATION])
        : MapLocation.empty();
    const searchTags = document[GroupDetails.SEARCH_TAGS] || [];

    const votes =
      json[GroupDetails.DELIVERY_DATE_VOTES] != null
        ? Object.fromEntries(
            Object.entries(json[GroupDetails.DELIVERY_DATE_VOTES])
          )
        : null;

    return new GroupDetails({
      offerId: json[GroupDetails.OFFER_ID] || "",
      sellerId: json[GroupDetails.SELLER_ID] || "",
      products: productsMap,
      minAmount: parseInt(json[GroupDetails.MIN_AMOUNT]),
      isMinimumValue: json[GroupDetails.IS_MINIMUM_VALUE] || true,
      prepDays: json[GroupDetails.PREP_DAYS] || "",
      memberOrders: memberOrders,
      hostId: json[GroupDetails.HOST_ID] || "",
      createdOn: GroupDetails.CREATED_ON,
      updatedOn: GroupDetails.UPDATED_ON,
      groupExpiry: GroupDetails.GROUP_EXPIRY_DATE,
      title: json[GroupDetails.TITLE] || "",
      pickupLocation: location,
      status: status.groupStatus,
      chatMessages: messages,
      invitationCode: json[GroupDetails.INVITATION_CODE],
      searchTags: searchTags,
      deliveryDate: json[GroupDetails.DELIVERY_DATE] ?? null,
      maxAmount: json[GroupDetails.MAX_AMOUNT]
        ? parseInt(json[GroupDetails.MAX_AMOUNT])
        : null,
      refunded: json[GroupDetails.REFUNDED],
      proposedDeliveryDate: json[GroupDetails.PROPOSED_DELIVERY_DATE] ?? null,
      deliveryDateVotes: votes,
    });
  }
}

class MapLocation {
  static STREET = "street";
  static POSTAL_CODE = "postalCode";
  static LATITUDE = "latitude";
  static LONGITUDE = "longitude";
  static GEOHASH = "geohash";

  constructor(street, postalCode, latitude, longitude, geohash) {
    this.street = street;
    this.postalCode = postalCode;
    this.latitude = latitude;
    this.longitude = longitude;
    this.geohash = geohash;
  }

  static empty() {
    return new MapLocation("", "");
  }

  static fromJson(json) {
    return new MapLocation(
      json[MapLocation.STREET] || "",
      json[MapLocation.POSTAL_CODE] || "",
      json[MapLocation.LATITUDE]
        ? parseFloat(json[MapLocation.LATITUDE])
        : null,
      json[MapLocation.LONGITUDE]
        ? parseFloat(json[MapLocation.LONGITUDE])
        : null,
      json[MapLocation.GEOHASH]
    );
  }

  static fromOneMapsApiRes(data) {
    const lat = data["LATITUDE"] !== null ? parseFloat(data["LATITUDE"]) : 0.0;
    const long =
      data["LONGITUDE"] !== null ? parseFloat(data["LONGITUDE"]) : 0.0;
    const geoHash = null;
    return new MapLocation(
      data["ADDRESS"] || "",
      data["POSTAL"] || "",
      lat,
      long,
      geoHash
    );
  }

  toJson() {
    return {
      [MapLocation.STREET]: this.street,
      [MapLocation.POSTAL_CODE]: this.postalCode,
      [MapLocation.LATITUDE]: this.latitude,
      [MapLocation.LONGITUDE]: this.longitude,
      [MapLocation.GEOHASH]: this.geohash,
    };
  }
}

export class SellerDetails {
  static SHOP_NAME = 'shopName';
  static UEN = 'uen';
  static ADDRESS = 'address';
  static MOBILE = 'mobile';
  static EMAIL = 'email';
  static CONTACT_PERSON = 'contactPerson';
  static IMAGE_URL = 'imageUrl';
  static GROUPS = 'groups';
  static NOTIFICATIONS = 'notifications';
  static SELLER_STATUS = 'sellerStatus';
  static CREATED_ON = 'createdOn';
  static UPDATED_ON = 'updatedOn';
  static NOTIFICATION_TOKENS = 'notificationTokens';
  static SEARCH_TAGS = 'searchTags';
  static CATEGORY = 'category';
  static MARGIN = 'margin';
  static NOTIFY = 'notify';
  static IS_TESTER = 'isTester';
  static MOQ_THRESHOLD = 'moqThreshold';

  constructor({
    shopName,
    address,
    mobile,
    email,
    groups,
    contactPerson,
    notifications,
    createdOn,
    updatedOn,
    status,
    uen,
    notificationTokens,
    searchTags,
    category,
    margin,
    notify,
    isTester,
    moqThreshold,
    imageURL,
  }) {
    this.shopName = shopName;
    this.uen = uen;
    this.address = address;
    this.mobile = mobile;
    this.email = email;
    this.contactPerson = contactPerson;
    this.groups = groups;
    this.notifications = notifications;
    this.status = status;
    this.createdOn = createdOn;
    this.updatedOn = updatedOn;
    this.notificationTokens = notificationTokens;
    this.searchTags = searchTags;
    this.category = category;
    this.margin = margin;
    this.notify = notify;
    this.isTester = isTester;
    this.moqThreshold = moqThreshold;
    this.imageURL = imageURL;
  }

  static empty() {
    return new SellerDetails({
      shopName: '',
      uen: '',
      address: MapLocation.empty(),
      mobile: '',
      email: '',
      groups: [],
      contactPerson: '',
      createdOn: new Date(),
      updatedOn: new Date(),
      notifications: [],
      status: 'UNKNOWN',
      notificationTokens: {},
      searchTags: [],
      category: '',
      margin: 0,
      notify: true,
      isTester: false,
    });
  }

  static fromData(data) {
    const status = data[SellerDetails.SELLER_STATUS] || 'UNKNOWN';
    const location = data[SellerDetails.ADDRESS] ? MapLocation.fromJson(data[SellerDetails.ADDRESS]) : MapLocation.empty();
    const notificationTokens = data[SellerDetails.NOTIFICATION_TOKENS] || {};
    const tokens = Object.entries(notificationTokens).reduce((acc, [key, value]) => {
      acc[key] = new Date(value.toDate());
      return acc;
    }, {});
    const searchTags = document[SellerDetails.SEARCH_TAGS] || [];
    const margin = data[SellerDetails.MARGIN] !== null ? data[SellerDetails.MARGIN].toString() : '0';
    return new SellerDetails({
      shopName: data[SellerDetails.SHOP_NAME] || '',
      uen: data[SellerDetails.UEN] || '',
      address: location,
      mobile: data[SellerDetails.MOBILE] || '',
      email: data[SellerDetails.EMAIL] || '',
      groups: data[SellerDetails.GROUPS] || [],
      contactPerson: data[SellerDetails.CONTACT_PERSON] || '',
      createdOn: SellerDetails.CREATED_ON,
      updatedOn: SellerDetails.UPDATED_ON,
      status,
      notifications: data[SellerDetails.NOTIFICATIONS] || [],
      notificationTokens: tokens,
      searchTags,
      category: data[SellerDetails.CATEGORY] || '',
      margin: parseFloat(margin),
      notify: data[SellerDetails.NOTIFY] !== undefined ? data[SellerDetails.NOTIFY] : true,
      imageURL: data[SellerDetails.IMAGE_URL],
      moqThreshold: data[SellerDetails.MOQ_THRESHOLD],
      isTester: data[SellerDetails.IS_TESTER],
    });
  }
}
