import { useEffect, useState } from "react";
import { db } from "../../index";
import { doc, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import "./ProductListTile.css";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useNavigate } from "react-router-dom";

export default function ProductListTile({ product, productId }) {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    getImageData(product.imageURL);
    getSellerData(product.sellerId);
  }, [product]);

  const getImageData = async (downloadUrl) => {
    if (!downloadUrl) return
    const storage = getStorage();
    const imageUrl = await getDownloadURL(ref(storage, downloadUrl)).catch(
      (error) => {
        console.error(error);
      }
    );
    setImageUrl(imageUrl);
  };

  const getSellerData = async (sellerId) => {
    const collectionName = "sellers";
    const docRef = doc(db, collectionName, sellerId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const shopName = docSnap.data().shopName;
      setSellerName(shopName);
    } else {
      console.error("No such document!");
    }
  };

  const goToProductDetails = () => {
    navigate(`/product-details/${productId}`);
  };

  return (
    <div className="product-tile">
      <img
        className="product-img"
        onClick={() => setShowOverlay(true)}
        src={imageUrl !== '' ? imageUrl : '/placeholder.png'}
        alt="product_img"
      />
      <div>
        <p className="product-title">{product.name}</p>
        <p className="product-info">
          <b>Shop</b>: {sellerName}
        </p>
        <p className="product-info">
          <b>Category</b>: {product.category}
        </p>
        <p className="product-description">
          <b>Description</b>: {product.description}
        </p>
      </div>
      <div className="details-button" onClick={goToProductDetails}>
        <FormatListBulletedIcon />
        <p>Details</p>
      </div>
      {showOverlay && (
        <LargeImageOverlay
          imageUrl={imageUrl}
          onClose={() => setShowOverlay(false)}
        />
      )}
    </div>
  );
}

const LargeImageOverlay = ({ imageUrl, onClose }) => {
  return (
    <div className="overlay" onClick={onClose}>
      <div className="overlay-content">
        <img src={imageUrl} alt="product" onClick={(e) => e.stopPropagation()} />
      </div>
    </div>
  );
};
