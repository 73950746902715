import React, { useEffect, useState } from "react";
import "./RoleDescription.css";

export default function RoleDescription() {
  const [isSmallWidth, setIsSmallWidth] = useState(
    window.matchMedia("(max-width: 999px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 999px)")
      .addEventListener("change", (e) => setIsSmallWidth(e.matches));
  });
  return (
    <div className="roles-container">
      <p>HOST OR JOIN A GROUP</p>
      <div className={`role${isSmallWidth ? "col" : ""} `}>
        <div className="role-title">AS A HOST</div>
        <div className="role-description">
          <div className="role-description-item">
            <img
              className="role-description-icon"
              src={"/delivery_icon.png"}
              alt="deliveries"
            />
            <p>
              Remain in comfort as deliveries are made to you
            </p>
          </div>
          <div className="role-divider" />
          <div className="role-description-item">
            <img
              className="role-description-icon"
              src={"/distribute_icon.png"}
              alt="deliveries"
            />
            <p>Distribute products to group participants</p>
          </div>
          {isSmallWidth && <div className="role-divider" />}
        </div>
      </div>
      {isSmallWidth || <div className="role-divider" />}
      <div className={`role${isSmallWidth ? "col" : ""} `}>
        <div className="role-title">AS A PARTICIPANT</div>
        <div className="role-description">
          <div className="role-description-item">
            <img
              className="role-description-icon"
              src={"/join_group_icon.png"}
              alt="deliveries"
            />
            <p>Join groups that are near you</p>
          </div>
          <div className="role-divider" />
          <div className="role-description-item">
            <img
              className="role-description-icon"
              src={"/collect_icon.png"}
              alt="deliveries"
            />
            <p>Collect your products from the host</p>
          </div>
        </div>
      </div>
    </div>
  );
}
