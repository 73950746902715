import React, { useEffect } from "react";
import "./OurStory.css";

export default function OurStory() {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
    <div className="our-story">
      <h1>OUR STORY</h1>
      <p className="our-story-content">
        Bizbulk connects <b>curated reputable suppliers of premium products</b>{" "}
        to buyers who meet a minimum order quantity, and therefore are offered
        great deals.
        <br />
        <br />
        The conceptualization of Bizbulk birthed due to the constant request
        from friends/family to assist in purchasing trusted premiums products
        (e.g. Sashimi/Steak) which were discounted due to the Founder having
        purchased directly from the suppliers before.
        <br />
        <br />
        As friends/family spread the word on the attractive deals, the requests
        grew and soon the manual process of arranging orders became too tedious.
        <br />
        <br />
        The plan was simple - get rid of the need to have a middle man manually
        organizing group buys.
        <br />
        <br />
        Thus Bizbulk was created. Bizbulk hopes each and every product that is
        purchased on the platform .
      </p>
    </div>
  );
}
