import React from "react";
import "./ShowcaseProducts.css";

export default function ShowcaseProducts() {
  return (
    <div className="showcase-products-container">
      <h1>
        <b>PREMIUM PRODUCTS</b> - Quality products that are commercially used
      </h1>
      <div className="showcase-products">
        <img className="showcase-product" src="sashimi.png" alt="sashimi" />
        <img className="showcase-product" src="wagyu_steak.png" alt="wagyu" />
        <img className="showcase-product" src="fine_wine.png" alt="fine_wine" />
        <img className="showcase-product" src="ribeye.png" alt="ribeye" />
      </div>
    </div>
  );
}
