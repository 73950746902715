import { useEffect, useState } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import "./SellersList.css";

export default function SellersListTile({ seller }) {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const getImageData = async (downloadUrl) => {
      const storage = getStorage();
      const imageUrl = await getDownloadURL(ref(storage, downloadUrl)).catch(
        (error) => {
          console.error(error);
        }
      );
      setImageUrl(imageUrl);
    };
    getImageData(seller.imageURL);
  }, [seller]);

  return (
    <div className="seller-tile-wrapper">
      <div className="seller-tile">
        <img className="seller-img" src={imageUrl} alt="seller_img" />
        <div>
          <p className="seller-title">{seller.shopName}</p>
        </div>
        <div className="products-list"></div>
      </div>
    </div>
  );
}
