import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import { getFunctions, httpsCallable } from "firebase/functions";

export default function ContactUs() {
  const [sender, setSender] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const functions = getFunctions();
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  const sendEmail = httpsCallable(functions, "contactUsFromWeb");
  const onSubmit = (e) => {
    e.preventDefault();
    sendEmail({ sender: sender, userEmail: email, messageBody: message })
      .then((result) => {
        alert(
          `${
            result.data
              ? "Message sent!"
              : "An error has occured. Please try again."
          }`
        );
      })
      .catch((error) => {
        console.error(`error: ${JSON.stringify(error)}`);
      });
  };
  return (
    <div className="contact-us">
      <h1>CONTACT US</h1>
      <form onSubmit={onSubmit} className="contact-form">
        <input
          type="text"
          onChange={(e) => setSender(e.target.value)}
          placeholder="Name"
        />
        <input
          type="text"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <textarea
          placeholder="Your message"
          rows="5"
          onChange={(e) => setMessage(e.target.value)}
        />
        <input type="submit" />
      </form>
    </div>
  );
}
