import { useEffect, useState } from "react";
import { db } from "../../../index";
import { useNavigate, useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  OfferDetails,
  ProductDetails,
} from "../../../constants/FirestoreClasses";
import OffersListTile from "../../Webapp/OffersListTile";
import AppDownloadModal from "../../Webapp/AppDownloadModal";
import ProductPreviewSheet from "./ProductPreviewSheet";
import "./ProductPreview.css";
import { appRoutes } from "../../../constants/Routes";
import { Helmet } from "react-helmet";

export default function ProductPreview() {
  const params = useParams();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [product, setProduct] = useState(null);
  const [offers, setOffers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!product) return;
    getImageData(product.imageURL);
  }, [product]);

  const getImageData = async (downloadUrl) => {
    const storage = getStorage();
    const imageUrl = await getDownloadURL(ref(storage, downloadUrl)).catch(
      (error) => {
        console.error(error);
      }
    );
    setImageUrl(imageUrl);
  };

  useEffect(() => {
    const getProductData = async (productId) => {
      const collectionName = "products";
      const docRef = doc(db, collectionName, productId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const productDetails = ProductDetails.fromJson(docSnap.data());
        setProduct(productDetails);
      } else {
        navigate(appRoutes.webapp);
      }
    };
    async function getOffers(productId) {
      const offersCollection = collection(db, "offers");
      const productIdsField = "productIds";
      const offersMap = {};

      const q = query(
        offersCollection,
        where(productIdsField, "array-contains", productId)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        offersMap[doc.id] = OfferDetails.fromJson(doc.data());
      });
      setOffers(offersMap);
    }

    if (!params.productId || params.productId === "") return;
    getProductData(params.productId);
    getOffers(params.productId);
  }, [params.productId, navigate]);

  return (
    <div className="product-preview">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{product?.name ?? "Product details"}</title>
      </Helmet>
      <AppDownloadModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <img
        className="product-large-img"
        src={imageUrl}
        alt="product_large_img"
      />
      {product !== null ? (
        <ProductPreviewSheet product={product} />
      ) : (
        <p>Loading...</p>
      )}
      <div className="product-offers-list">
        {Object.keys(offers).length > 0 ? (
          Object.keys(offers).map((key) => (
            <OffersListTile
              key={key}
              offer={offers[key]}
              setIsModalOpen={setIsModalOpen}
            />
          ))
        ) : (
          <p>No offers to show</p>
        )}
      </div>
    </div>
  );
}
