export const appRoutes = {
    home: '/',
    ourStory: '/our-story',
    howToUse: '/how-to-use',
    contactUs: '/contact-us',
    faq: '/faq',
    termsAndConditions: '/terms-and-conditions',
    webapp: '/webapp',
    previewProducts: "/product-details/:productId",
    liveGroupDetails: "/live-group-details/:groupId",
    paynow: '/paynow/:clientSecret',
    error: "*"
}