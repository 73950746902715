import React from "react";
import "./AppStoreLinks.css";

export default function AppStoreLinks({isModalView}) {
  const googleUrl =
    "https://play.google.com/store/apps/details?id=com.bizbulk.app";
  const appleUrl = "https://apps.apple.com/sg/app/bizbulk/id6444786620";
  const handleClick = (url) => {
    window.open(url, "_blank");
  };
  return (
    <>
      <div className={`${isModalView ? 'app-store-links-modal' : 'app-store-links'}`}>
        <h1>DOWNLOAD THE APP TO START HOSTING OR JOINING GROUPS!</h1>
        <div className="app-store-button-row">
          <div
            className="app-store-button"
            onClick={() => handleClick(googleUrl)}
          >
            <img
              className="app-store-button-image"
              src={"/google_play_badge.png"}
              alt="Google button"
            />
          </div>
          <div
            className="app-store-button"
            onClick={() => handleClick(appleUrl)}
          >
            <img
              className="app-store-button-image"
              src={"/apple_app_store_badge.png"}
              alt="Google button"
            />
          </div>
        </div>
      </div>
      {isModalView || <div className="divider" />}
    </>
  );
}
