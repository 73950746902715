import { useNavigate } from "react-router-dom";
import "./PreviewHeader.css";
import { appRoutes } from "../../constants/Routes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";

export default function PreviewHeader() {
  const navigate = useNavigate();
  const [isIconLarge, setIsIconLarge] = useState(true);
  useEffect(() => {
    window
      .matchMedia("(max-width: 499px)")
      .addEventListener("change", (e) => setIsIconLarge(!e.matches));
  });
  return (
    <div className="preview-header">
      <div className="back-button" onClick={() => navigate(appRoutes.home)}>
        <ArrowBackIcon fontSize={`${isIconLarge ? "large" : "medium"}`}/>
      </div>
      <img
        className="preview-header-logo"
        src={"/logo_rect_mix.png"}
        alt="app_logo"
      />
      <div style={{ opacity: '0' }}>
        <ArrowBackIcon />
      </div>
    </div>
  );
}
