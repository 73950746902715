import { Helmet } from "react-helmet";
import "./App.css";
import ContactUs from "./components/ContactUs/ContactUs";
import HomeFooter from "./components/HomePage/HomeFooter";
import HomeHeader from "./components/HomePage/HomeHeader";
import { appRoutes } from "./constants/Routes";

export default function ContactUsPage() {
  return (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Bizbulk</title>
        <meta name="description" content="Contact Bizbulk" />
        <link rel="canonical" href={appRoutes.contactUs} />
      </Helmet>
      <HomeHeader />
      <ContactUs />
      <HomeFooter />
    </div>
  );
}
