import React from "react"
import { Helmet } from "react-helmet"
import { useSearchParams } from "react-router-dom"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Paynow } from "./components/Paynow/Paynow"

export default function PaynowPage() {
  const [URLSearchParams] = useSearchParams()
  const isTest = JSON.parse(URLSearchParams.get("isTest")) ?? false
  const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  const stripePublishableTestKey =
    process.env.REACT_APP_STRIPE_PUBLISHABLE_TEST_KEY
  const stripePromise = loadStripe(
    isTest ? stripePublishableTestKey : stripePublishableKey
  )
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <script src="https://js.stripe.com/v3/"></script>
      </Helmet>
      <Elements stripe={stripePromise}>
        <Paynow />
      </Elements>
    </>
  )
}

