import { useEffect } from "react";
import "./App.css";
import DotsSlider from "./components/HomePage/DotsSlider";
import HomeFooter from "./components/HomePage/HomeFooter";
import HomeHeader from "./components/HomePage/HomeHeader";
import RoleDescription from "./components/HomePage/RoleDescription";
import ShowcaseProducts from "./components/HomePage/ShowcaseProducts";
import Suppliers from "./components/HomePage/Suppliers";
import { Helmet } from "react-helmet";
import AppStoreLinks from "./components/Webapp/AppStoreLinks";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bizbulk - Group buys for premium products</title>
        <meta
          name="description"
          content="Bizbulk is the platform to purchase products you would normally find
          in high-end restaurants/shops at a hugely discounted price"
        />
        <link rel='canonical' href='/' />
      </Helmet>
      <HomeHeader />
      <DotsSlider />
      <ShowcaseProducts />
      <RoleDescription />
      <AppStoreLinks />
      <Suppliers />
      <HomeFooter />
    </div>
  );
}
