import { useState } from "react";
import "./App.css";
import PreviewFooter from "./components/Webapp/PreviewFooter";
import PreviewHeader from "./components/Webapp/PreviewHeader";
import ProductList from "./components/Webapp/ProductsList";
import { Helmet } from "react-helmet";
import { appRoutes } from "./constants/Routes";
import SellersList from "./components/Webapp/SellerList";

export default function Preview() {
  const [isProductsList, setIsProductsList] = useState(true);

  return (
    <div className="app-preview">
      <Helmet>
        <meta charSet="utf-8" />
        <title>App preview</title>
        <meta name="description" content="Bizbulk webapp" />
        <link rel="canonical" href={appRoutes.webapp} />
      </Helmet>
      <PreviewHeader />
      {isProductsList ? <ProductList /> : <SellersList />}
      <PreviewFooter setIsProductsList={setIsProductsList} />
    </div>
  );
}
