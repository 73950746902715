import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { initializeApp } from "firebase/app";
import TermsAndConditionsPage from './TermsAndConditionsPage';
import Preview from './Preview';
import { getFirestore } from "firebase/firestore";
import PageNotFound from './PageNotFound';
import HomePage from './HomePage';
import HowToUse from './HowToUse';
import { appRoutes as AppRoutes } from './constants/Routes';
import FaqPage from './FaqPage';
import OurStoryPage from './OurStoryPage';
import PreviewProductPage from './PreviewProductPage';
import ContactUsPage from './ContactUsPage';
import PaynowPage from './PaynowPage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const router = createBrowserRouter([
  {
    path: AppRoutes.home,
    element: <HomePage />,
  },
  // {
  //   path: AppRoutes.howToUse,
  //   element: <HowToUse />,
  // },
  {
    path: AppRoutes.ourStory,
    element: <OurStoryPage />,
  },
  {
    path: AppRoutes.faq,
    element: <FaqPage />,
  },
  {
    path: AppRoutes.termsAndConditions,
    element: <TermsAndConditionsPage />,
  },
  {
    path: AppRoutes.webapp,
    element: <Preview />,
  },
  {
    path: AppRoutes.contactUs,
    element: <ContactUsPage />,
  },
  {
    path: AppRoutes.previewProducts,
    element: <PreviewProductPage />,
  },
  {
    path: AppRoutes.paynow,
    element: <PaynowPage/>,
  },
  {
    path: AppRoutes.liveGroupDetails,
    element: <PageNotFound showAppRequiredMessage/>,
  },
  {
    path: AppRoutes.error,
    element: <PageNotFound />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
