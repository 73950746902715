import { Helmet } from "react-helmet";
import "./App.css";
import FaqContent from "./components/FaqPage/Faq";
import HomeFooter from "./components/HomePage/HomeFooter";
import HomeHeader from "./components/HomePage/HomeHeader";
import { appRoutes } from "./constants/Routes";

export default function FaqPage() {
  return (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Frequently asked questions</title>
        <meta name="description" content="Frequently asked questions" />
        <link rel="canonical" href={appRoutes.faq} />
      </Helmet>
      <HomeHeader />
      <FaqContent />
      <HomeFooter />
    </div>
  );
}
