import React, { useEffect, useRef, useState } from "react";
import "./DotsSlider.css";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "../../constants/Routes";

export default function DotsSlider() {
  const navigate = useNavigate();
  const [imageindex, setImageIndex] = useState(0);
  const timerRef = useRef(null);
  const imageUrls = [
    "/kaisen_don.jpg",
    "/gyudon.jpg",
    "/banner.jpg",
  ];

  useEffect(() => {
    const handleTimeout = () => {
      let nextIndex = imageindex === imageUrls.length - 1 ? 0 : imageindex + 1;
      setImageIndex(nextIndex);
    };

    timerRef.current = setTimeout(handleTimeout, 5000);
  }, [imageindex, imageUrls.length]);

  const handleDotClick = (index) => {
    clearTimeout(timerRef.current);
    setImageIndex(index);
  };
  const goToPreview = () => {
    navigate(appRoutes.webapp);
  };

  return (
    <div className="dots-slider">
      {imageUrls.map((url, i) => {
        return (
          <img
            key={i}
            className={`dots-slide ${imageindex === i ? "active" : ""}`}
            src={url}
            alt={`slide ${i}`}
          />
        );
      })}
      <div className="slider-overlay">
        <div onClick={goToPreview} className="preview-app-button">
          Preview Webapp
        </div>
        <div className="dots-row">
          {imageUrls.map((url, i) => {
            return (
              <span
                key={i}
                className={`dot ${imageindex === i ? "active" : ""}`}
                onClick={() => handleDotClick(i)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
