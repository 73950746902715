import { Helmet } from "react-helmet";
import "./App.css";
import HomeFooter from "./components/HomePage/HomeFooter";
import HomeHeader from "./components/HomePage/HomeHeader";
import { termsAndConditions } from "./constants/TermsAndConditions";
import { appRoutes } from "./constants/Routes";

export default function TermsAndConditionsPage() {
  const paragraphs = termsAndConditions
    .split("\n")
    .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  return (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms and conditions</title>
        <meta name="description" content="Bizbulk's T&Cs" />
        <link rel="canonical" href={appRoutes.termsAndConditions} />
      </Helmet>
      <HomeHeader />
      <h1>TERMS AND CONDITIONS</h1>
      <div className="t-and-c-page">{paragraphs}</div>
      <HomeFooter />
    </div>
  );
}
