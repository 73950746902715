import { useEffect, useState } from "react";
import { db } from "../../index";
import { collection, getDocs, query, where } from "firebase/firestore";
import "./SellersList.css";
import { SellerDetails } from "../../constants/FirestoreClasses";
import SellersListTile from "./SellersListTile";

export default function SellersList() {
  const [sellers, setSellers] = useState({});

  useEffect(() => {
    const getData = async () => {
      const collectionName = "sellers";
      const collection_ref = collection(db, collectionName);
      const q = query(collection_ref, where("isTester", "==", false));
      const querySnapshot = await getDocs(q);
      const tempSellers = {};
      querySnapshot.forEach((doc) => {
        const seller = SellerDetails.fromData(doc.data());
        tempSellers[doc.id] = seller;
      });
      setSellers(tempSellers);
    };
    getData();
  }, []);

  return (
    <div className="preview-list">
      <p className="preview-title">Sellers</p>
      {Object.keys(sellers).length > 0 ? (
        Object.keys(sellers).map((key) => (
          <SellersListTile
            key={key}
            seller={sellers[key]}
          />
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
