import React from "react"
import "./LoadingOverlay.css"

export function LoadingOverlay() {
  return (
    <div className="loading-overlay">
      <div className="loading-spinner"/>
    </div>
  )
}
