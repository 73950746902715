import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./PreviewFooter.css";
import LoginIcon from "@mui/icons-material/Login";
import StoreIcon from '@mui/icons-material/Store';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { appRoutes } from "../../constants/Routes";

export default function PreviewFooter({ setIsProductsList }) {
  const navigate = useNavigate();
  const [isIconLarge, setIsIconLarge] = useState(true);
  useEffect(() => {
    window
      .matchMedia("(max-width: 499px)")
      .addEventListener("change", (e) => setIsIconLarge(!e.matches));
  });
  return (
    <div className="preview-footer">
      <div
        className="icon"
        onClick={() => {
          setIsProductsList(false);
        }}
      >
        <StoreIcon fontSize={`${isIconLarge ? "large" : "medium"}`}/>
        <p>Sellers</p>
      </div>
      <div
        className="icon"
        onClick={() => {
          setIsProductsList(true);
        }}
      >
        <ShoppingCartIcon fontSize={`${isIconLarge ? "large" : "medium"}`}/>
        <p>Products</p>
      </div>
      <div
        className="icon"
        onClick={() => {
          navigate(appRoutes.home);
        }}
      >
        <LoginIcon fontSize={`${isIconLarge ? "large" : "medium"}`}/>
        <p>Back</p>
      </div>
    </div>
  );
}
