import { useEffect, useState } from "react";
import { db } from "../../index";
import { collection, getDocs, query } from "firebase/firestore";
import "./ProductsList.css";
import { ProductDetails } from "../../constants/FirestoreClasses";
import ProductListTile from "./ProductListTile";

export default function ProductList() {
  const [products, setProducts] = useState({});
  const getData = async () => {
    const collectionName = "products";
    const collection_ref = collection(db, collectionName);
    const q = query(collection_ref);
    const querySnapshot = await getDocs(q);
    const productsMap = {};
    querySnapshot.forEach((doc) => {
      const product = ProductDetails.fromJson(doc.data());
      productsMap[doc.id] = product;
    });
    setProducts(productsMap);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="preview-list">
      <p className="preview-title">Products</p>
      {Object.keys(products).length > 0 ? (
        Object.keys(products).map((key) => (
          <ProductListTile key={key} productId={key} product={products[key]} />
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
