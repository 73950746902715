import React from "react";
import "./Suppliers.css";

export default function Suppliers() {
  return (
    <div className="suppliers">
      <p>REPUTABLE SUPPLIERS</p>
      <div className="suppliers-logo-display">
        <img
          className="suppliers-logo"
          src={"/atom_logo.jpg"}
          alt="Atom logo"
        />
        <img
          className="suppliers-logo"
          src={"/fine_brands_logo.jpg"}
          alt="Fine Brands logo"
        />
        <img
          className="suppliers-logo"
          src={"/oishii_logo.jpg"}
          alt="Oishii logo"
        />
        <img
          className="suppliers-logo-heinrich"
          src={"/heinrich_logo.jpg"}
          alt="Heinrich logo"
        />
      </div>
    </div>
  );
}
