import { Link } from "react-router-dom";
import { appRoutes } from "./constants/Routes";
import AppStoreLinks from "./components/Webapp/AppStoreLinks";

export default function PageNotFound({ showAppRequiredMessage }) {
  return (
    <div className="error-page">
      <h1>
        {showAppRequiredMessage
          ? "You have been sent a link which requires the Bizbulk app to open"
          : "Oops! This page does not exist."}
      </h1>
      {showAppRequiredMessage && <AppStoreLinks/>}
      <p>Back to home:</p>
      <Link to={appRoutes.home}>Home</Link>
    </div>
  );
}
