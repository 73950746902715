import { useEffect, useState } from "react";
import { db } from "../../index";
import { doc, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import "./OffersList.css";

export default function OffersListTile({ offer, setIsModalOpen }) {
  const [imageUrl, setImageUrl] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [productNames, setProductNames] = useState([]);

  useEffect(() => {
    const getImageData = async (downloadUrl) => {
      const storage = getStorage();
      const imageUrl = await getDownloadURL(ref(storage, downloadUrl)).catch(
        (error) => {
          console.error(error);
        }
      );
      setImageUrl(imageUrl);
    };

    const getSellerData = async (sellerId) => {
      const collectionName = "sellers";
      const docRef = doc(db, collectionName, sellerId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const sellerDetails = docSnap.data();
        setSellerName(sellerDetails.shopName);
        getImageData(sellerDetails.imageUrl);
      } else {
        console.error("No such document!");
      }
    };

    const getProductsList = async (productIds) => {
      const productNamesPromises = productIds.map(async (id) => {
        const collectionName = "products";
        const docRef = doc(db, collectionName, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const productDetails = docSnap.data();
          return productDetails.name;
        } else {
          console.error("No such document!");
        }
      });

      const productNames = await Promise.all(productNamesPromises);
      setProductNames(productNames);
    };

    getSellerData(offer.sellerId);
    getProductsList(offer.productIds);
  }, [offer]);

  const getMinValueAmountString = (isMinimumValue) =>
    isMinimumValue === true ? "value" : "amount";
  const getValueString = (value) => {
    const result = value / 100;
    return `$${result.toFixed(2)}`;
  };

  const onClickViewGroups = () => {
    setIsModalOpen(true);
  };
  const onClickHostGroup = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="offer-tile-wrapper">
      <div className="offer-tile">
        <div className="seller-display">
          <img className="seller-img" src={imageUrl} alt="seller_img" />
          {sellerName}
        </div>
        <div>
          <p className="offer-title">{offer.title}</p>
          <p className="offer-info">
            <b>Total products</b>: {offer.productIds.length}
          </p>
          <p className="offer-info">
            <b>Min {getMinValueAmountString(offer.isMinimumValue)}</b>:{" "}
            {offer.isMinimumValue
              ? getValueString(offer.minAmount)
              : offer.minAmount}
          </p>
          <p className="offer-description">
            <b>Description</b>: {offer.description}
          </p>
        </div>
        <div className="products-list">
          <b>Items</b>
          {productNames.length > 0 ? (
            productNames.map((name, i) => <p key={i}>{name}</p>)
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
      <div className="button-row">
        <div className="offers-button" onClick={onClickViewGroups}>
          <p>View groups</p>
        </div>
        <div className="offers-button" onClick={onClickHostGroup}>
          <p>Host group</p>
        </div>
      </div>
    </div>
  );
}
