import React from "react";
import "./HomeFooter.css";
import { appRoutes } from "../../constants/Routes";
import AppStoreLinks from "../Webapp/AppStoreLinks";

export default function HomeFooter() {
  const facebook = "https://www.facebook.com/profile.php?id=100094960745653";
  const insta = "https://www.instagram.com/bizbulk/";
  const handleClick = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="home-footer">
      <div className="footer-links">
        <p>Links</p>
        <a href={appRoutes.webapp}>Preview the app</a>
        <a href={appRoutes.termsAndConditions}>Terms and Conditions</a>
        <br />
        &copy;Bizbulk 2022
      </div>
      <div className="col-right">
        <p>
          <u>Our service</u>
          <br />
          <br />
          Bizbulk is the platform to purchase products you would normally find
          in high-end restaurants/shops at a hugely discounted price. The only
          condition is for customers to hit a Minimum Order Quantity/Value
          (MOQ/MOV)
        </p>
        <u>Social media</u>
        <div className="social-icon-row">
          <img
            onClick={() => handleClick(facebook)}
            className="social-icon"
            src={"/facebook.png"}
            alt="facebook logo"
          />
          <img
            onClick={() => handleClick(insta)}
            className="social-icon"
            src={"/insta.png"}
            alt="instagram logo"
          />
        </div>
      </div>
    </div>
  );
}
