import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useStripe } from "@stripe/react-stripe-js"
import "./Paynow.css"
import { LoadingOverlay } from "./LoadingOverlay"

export function Paynow() {
  const stripe = useStripe()
  const params = useParams()
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const confirmPayment = useCallback(() => {
    if (!stripe || !params.clientSecret) return
    setIsLoading(true)
    stripe.confirmPayNowPayment(params.clientSecret).then((res) => {
      if (res.error) {
        console.error(res.error)
        setHasError(true)
        return
      }
      if (res.paymentIntent.status === "succeeded") {
        window.PAYNOW_REQUEST.postMessage("success")
      }
    })
    setIsLoading(false)
  }, [params, stripe])

  useEffect(() => {
    confirmPayment()
  }, [confirmPayment])

  const handleCall = () => {
    confirmPayment()
  }
  return (
    <>
      <div className="paynow-page">
        {isLoading && <LoadingOverlay />}
        <h1>Paynow Payment</h1>
        {hasError ? (
          <p>
            There appears to be an error with the QR code. Please return to the
            previous screen and try gain
          </p>
        ) : (
          <button onClick={handleCall}>Get QR code</button>
        )}
      </div>
    </>
  )
}
