import Modal from "react-modal";
import AppStoreLinks from "./AppStoreLinks";
import "./AppDownloadModal.css";

export default function AppDownloadModal({ isModalOpen, setIsModalOpen }) {
  const closeModal = () => setIsModalOpen(false);
  const customStyles = {
    overlay: {
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        "background-color": "rgba(0,0,0,0.75)",
    },
    content: {
        top: "30%",
        position: "fixed",
        height: "min-content",
        "border-radius": "32px",
        "font-weight": "700",
      },
  };

  return (
    <Modal
      style={customStyles}
      ariaHideApp={false}
      isOpen={isModalOpen}
      contentLabel="App download modal"
      onRequestClose={closeModal}
    >
      <AppStoreLinks isModalView/>
      <div className="close-button" onClick={closeModal}>
        Close
      </div>
    </Modal>
  );
}
