import React, { useEffect, useState } from "react";
import "./HomeHeader.css";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CancelIcon from "@mui/icons-material/Cancel";
import { appRoutes } from "../../constants/Routes";

export default function HomeHeader() {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [isIconLarge, setIsIconLarge] = useState(true);
  const [hasSideBar, setHasSideBar] = useState(
    window.matchMedia("(max-width: 999px)").matches
  );
  const location = useLocation();
  useEffect(() => {
    window
      .matchMedia("(max-width: 999px)")
      .addEventListener("change", (e) => setHasSideBar(e.matches));
    window
      .matchMedia("(max-width: 499px)")
      .addEventListener("change", (e) => setIsIconLarge(!e.matches));
  });
  return (
    <div className="main-header">
      <div className="headline">
        <p>A GROUP-BUY APP - PREMIUM PRODUCTS AT YOUR DOORSTEP</p>
      </div>
      <div className="header-banner">
        {hasSideBar && (
          <MenuIcon
            onClick={() => setSideBarOpen(!sideBarOpen)}
            color="action"
            fontSize={`${isIconLarge ? "large" : "medium"}`}
          />
        )}
        <img width="30%" src={"/logo_rect_mix.png"} alt="app_logo" />
        {hasSideBar && (
          <div style={{ opacity: '0'}}>
          <MenuIcon
            fontSize={`${isIconLarge ? "large" : "medium"}`}
          /></div>
        )}
      </div>
      {hasSideBar && (
        <SideBar sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen} />
      )}
      <NavBar currentPath={location.pathname} />
      <div className="header-divider" />
    </div>
  );
}

function NavBar({ currentPath }) {
  const navigate = useNavigate();
  const handleClick = (url) => {
    navigate(url);
  };
  return (
    <div className="nav-bar">
      <p
        style={{
          fontWeight: `${currentPath === appRoutes.home ? "700" : ""}`,
        }}
        onClick={() => handleClick(appRoutes.home)}
      >
        HOME
      </p>
      <p
        style={{
          fontWeight: `${currentPath === appRoutes.ourStory ? "700" : ""}`,
        }}
        onClick={() => handleClick(appRoutes.ourStory)}
      >
        OUR STORY
      </p>
      <p
        style={{
          fontWeight: `${currentPath === appRoutes.webapp ? "700" : ""}`,
        }}
        onClick={() => handleClick(appRoutes.webapp)}
      >
        WEBAPP
      </p>
      {/* <p
        style={{
          fontWeight: `${currentPath === appRoutes.howToUse ? "700" : ""}`,
        }}
        onClick={() => handleClick(appRoutes.howToUse)}
      >
        How to use
      </p> */}
      <p
        style={{
          fontWeight: `${
            currentPath === appRoutes.termsAndConditions ? "700" : ""
          }`,
        }}
        onClick={() => handleClick(appRoutes.termsAndConditions)}
      >
        TERMS & CONDITIONS
      </p>
      <p
        style={{
          fontWeight: `${currentPath === appRoutes.contactUs ? "700" : ""}`,
        }}
        onClick={() => handleClick(appRoutes.contactUs)}
      >
        CONTACT US
      </p>
      <p
        style={{
          fontWeight: `${currentPath === appRoutes.faq ? "700" : ""}`,
        }}
        onClick={() => handleClick(appRoutes.faq)}
      >
        FAQ
      </p>
    </div>
  );
}

function SideBar({ sideBarOpen, setSideBarOpen }) {
  const navigate = useNavigate();
  const handleClick = (url) => {
    setSideBarOpen(false);
    navigate(url);
  };
  return (
    <div>
      {sideBarOpen && (
        <div onClick={() => setSideBarOpen(false)} className="side-bar-overlay" />
      )}
      <div className={`side-bar ${sideBarOpen ? "open" : ""}`}>
        <div onClick={() => setSideBarOpen(false)} className="close-icon">
          <CancelIcon fontSize="large" />
        </div>
        <p onClick={() => handleClick(appRoutes.home)}>Home</p>
        <div className="divider"/>
        <p onClick={() => handleClick(appRoutes.ourStory)}>Our story</p>
        <div className="divider"/>
        <p onClick={() => handleClick(appRoutes.webapp)}>Webapp</p>
        <div className="divider"/>
        {/* <p onClick={() => handleClick(appRoutes.howToUse)}>How to use</p>
        <div className="divider"/> */}
        <p onClick={() => handleClick(appRoutes.termsAndConditions)}>
          Terms & Conditions
        </p>
        <div className="divider"/>
        <p onClick={() => handleClick(appRoutes.contactUs)}>Contact us</p>
        <div className="divider"/>
        <p onClick={() => handleClick(appRoutes.faq)}>FAQ</p>
      </div>
    </div>
  );
}
